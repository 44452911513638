
import packageJson from '@/../package.json';
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

import { IonMenu, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonList, IonLabel, IonIcon, menuController } from "@ionic/vue";

import {
    documents,
    time,
    person,
    clipboard,
    car,
    copy,
    ticket,
    wallet,
    card,
    people,
    book,
    informationCircle,
    documentText,
    listCircle,
    hourglass,
    newspaper,
    calendar,
    cube,
    home,
    list,
    barcode,
    shareSocial,
} from "ionicons/icons";

import { config_menu } from "@/custom_config.js";
import { getAssetUrl } from "@/utils.js";

import { getUser } from "@/services/userStore";

export default {
    components: {
        IonMenu,
        IonHeader,
        IonContent,
        IonToolbar,
        IonTitle,
        IonItem,
        IonList,
        IonLabel,
        IonIcon,
    },
    setup() {
        const appVersion = packageJson.version ?  packageJson.version : '1.0.0';

        const router = useRouter();
        const menu = ref(null);

        const filteredMenuItems = computed(() => {
            const user = getUser();

            if (!user) return [];

            // Se il ruolo dell'utente non è incluso in allowedRoles, usa 'Employee' come fallback
            const userRole = config_menu.some((item) => item.allowedRoles.includes(user.users_type_value)) ? user.users_type_value : "Employee";

            return config_menu.filter((item) => item.show && item.allowedRoles.includes(userRole));
        });

        const changePage = (pageName) => {
            menuController.close("app-menu");
            router.push(`${pageName}`);
        };

        //Al decimo click mando al simulatore
        const counter = ref(0);

        const openSimulator = () => {
            counter.value++;

            if (counter.value === 10) {
                counter.value = 0; // reset counter
                menuController.close("app-menu");
                router.push("/simulatore");
            }
        };

        return {
            documents,
            time,
            person,
            clipboard,
            hourglass,
            car,
            router,
            copy,
            documentText,
            ticket,
            wallet,
            people,
            listCircle,
            card,
            book,
            newspaper,
            calendar,
            cube,
            informationCircle,
            home,
            list,
            barcode,
            shareSocial,
            changePage,
            openSimulator,
            //configMenu
            config_menu,
            filteredMenuItems,
            menu,
            getAssetUrl,
            appVersion
        };
    },
};
