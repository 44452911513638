/**
 * 
 * @param {string} path
 * @returns path to image on current server
 */
const getAssetUrl = (path) => {
    const baseUrl = process.env.VUE_APP_BASE_URL.replace(/\/$/, "") + "/app"; // Aggiunge "/app"
    return `${baseUrl}/${path.replace(/^\/+/, "")}`; // Rimuove "/" iniziali dal path
};

export { getAssetUrl };
