import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import axios from "axios";
/* Base url for the api */
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
//axios.defaults.baseURL = "http://192.168.11.141/firegui_crm/";

/* Fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* Font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* Specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";  // Corretto!
/* Add icons to the library */
library.add(fas);
library.add(fab);


/* vue3-signature */
//import Vue3Signature from "vue3-signature";

//import firebaseMessaging from "./firebase";
// Import the functions you need from the SDKs you need
/* import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyAymguREhNMAMw917C0qnGRYAkwPT2o8Fo",
    authDomain: "apph2-2e295.firebaseapp.com",
    projectId: "apph2-2e295",
    storageBucket: "apph2-2e295.appspot.com",
    messagingSenderId: "613154686569",
    appId: "1:613154686569:web:5232cd5406bac91e7884f8",
}; */

//import { firebaseConfig, vapidKey } from './config';

/**
 *
 * ! PWA Service Worker
 * */
import "./registerServiceWorker";

/**
 *
 * ! PWA Elements for Camera
 *
 */
import { defineCustomElements } from "@ionic/pwa-elements/loader";

/**
 *
 * ! Import localstorage global key
 *
 */
import { LOCALSTORAGE_KEY } from "@/custom_config";

/* if (localStorage.getItem(`${LOCALSTORAGE_KEY}`) != null && localStorage.getItem("ricordami") != null) {
    console.log(localStorage.getItem("ricordami"));
    router.replace("/tab1");
} */

const app = createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(IonicVue)
    //.use(Vue3Signature)
    .use(router);

app.provide("LOCALSTORAGE_KEY", LOCALSTORAGE_KEY);

//initializeApp(firebaseConfig);
// Firebase
//app.config.globalProperties.$messaging = firebaseMessaging;
//console.log(app.config.globalProperties.$messaging);

router.isReady().then(() => {
    app.mount("#app");
    defineCustomElements(window);
});
